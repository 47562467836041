import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({
      asyncOps,
      store,
    });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);
/*
  let lpTitleItem = {
    action: {
      type: "title",
    },
    label: "ns.admin.sideNav.nimbusContentTitle",
  };
*/

  let  serviceContract= {
         icon: "pencil-write",
         label: "artists.admin.nav.items.serviceContract",
         permissions: {
           method: "canAnySoft",
           rules: [
             {
               asset: "serviceContract",
               actions: ["update"],
               level: "some",
             },
             {
               asset: "serviceContract",
               actions: ["create"],
               level: "some",
             },
           ],
         },
         action: {
           type: "routerNavigation",
           data: {
             to: {
               name: "admin-entity-entityType",
               params: { entityType: "serviceContract" },
             },
           },
         },
       };



  let  conversationMessages= {
    icon: "chat-message-alt",
    label: "artists.admin.nav.items.conversationMessages",
    permissions: {
      method: "canAnySoft",
      rules: [
        {
          asset: "chatMessage",
          actions: ["read"],
          level: "all",
        },
      ],
    },
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-conversation-message",
        },
      },
    },
  };

  let  sourceTracking= {
    icon: "chat-message-alt",
    label: "artists.admin.nav.items.sourceTracking",
    permissions: {
      method: "hasGroup",
      rules: "superUsers",
    },
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-tracking",
        },
      },
    },
  };
//
  nav
    .addItemAfter("order", "serviceContract", serviceContract)
    .addItemBefore("entities", "conversationMessages", conversationMessages)
    .addItemAfter("user", "userAction",sourceTracking)
    .removeItems(["brand"]);
  return nav.getItems();
};
